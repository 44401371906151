import React from 'react'
import PropTypes from 'prop-types'

const PremiumFeatures = ({ plan }) => {
  return (
    <ul className="fa-ul">
      <li className="font-weight-bold">
        <span className="fa-li">
          <i className="fas fa-crown"></i>
        </span>
        {plan} Premium
      </li>
      <li>
        <span className="fa-li">
          <i className="fas fa-check"></i>
        </span>
        High Speed Downloads
      </li>
      <li>
        <span className="fa-li">
          <i className="fas fa-check"></i>
        </span>
        150 GB Daily Bandwidth
      </li>
      <li>
        <span className="fa-li">
          <i className="fas fa-check"></i>
        </span>
        Premium Hosts Support
      </li>
      <li>
        <span className="fa-li">
          <i className="fas fa-check"></i>
        </span>
        Unlimited Streaming
      </li>
      <li>
        <span className="fa-li">
          <i className="fas fa-check"></i>
        </span>
        Download History <small>(Enable/Disable)</small>
      </li>
      <li>
        <span className="fa-li">
          <i className="fas fa-check"></i>
        </span>
        Save Original links
      </li>
      <li>
        <span className="fa-li">
          <i className="fas fa-check"></i>
        </span>
        No Advertisements
      </li>
      <li>
        <span className="fa-li">
          <i className="fas fa-check"></i>
        </span>
        No waiting time
      </li>
      <li>
        <span className="fa-li">
          <i className="fas fa-check"></i>
        </span>
        24/7 Support
      </li>
    </ul>
  )
}

PremiumFeatures.propTypes = {
  plan: PropTypes.string.isRequired,
}

export default PremiumFeatures
