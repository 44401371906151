import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const plansMap = {
  1: process.env.LS_ONEMONTH_PLANID,
  2: process.env.LS_TWOMONTH_PLANID,
  3: process.env.LS_THREEMONTH_PLANID,
}

const UpgradeViaCard = ({ isLoggedIn, plan, user }) => {
  const [queryParams, setQueryParams] = useState('')
  const params = new URLSearchParams()

  useEffect(() => {
    if (window && window.createLemonSqueezy) {
      window.createLemonSqueezy()
    }
  }, [])

  useEffect(() => {
    params.set('embed', 1)
    params.set('media', 0)
    params.set('discount', 0)
    params.set('logo', 0)
    params.set('desc', 0)

    if (user && user.email) {
      params.set('checkout[email]', user.email)
      params.set('checkout[name]', user.username)
      params.set('checkout[custom][user_id]', user.email)
    }
    setQueryParams(params.toString())
  }, [user.email, isLoggedIn])

  return (
    <div className="mt-2 mb-1">
      {isLoggedIn ? (
        <a
          href={`https://cboxera.lemonsqueezy.com/buy/${plansMap[plan]}?${queryParams}`}
          className="lemonsqueezy-button btn btn-danger"
        >
          <i className="far fa-credit-card mr-2" />
          Pay with Card
        </a>
      ) : (
        <></>
      )}
    </div>
  )
}

UpgradeViaCard.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  // handleClick: PropTypes.func.isRequired,
  plan: PropTypes.number.isRequired,
}

export default UpgradeViaCard
