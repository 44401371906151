import React, { useContext, useState } from 'react'
import PaymentOptions from './PaymentOptions'
import { PaymentsProvider } from './PaymentsContext'
import UpgradeButton from './UpgradeButton'
import Voucher from '../PremiumKey'
import PremiumFeatures from './PremiumFeatures'

import { UserContext } from '../UserContext'
import LemonSqueezy from './LemonSqueezy'
import CryptoPayment from './CryptoPayment'

const showChristmasDeals = process.env.SHOW_CHRISTMAS_DEALS === 'true'

const Payments = () => {
  const [isOpen, setOpen] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState(1)
  const { isLoggedIn, user } = useContext(UserContext)

  const handleClick = plan => e => {
    e.preventDefault()
    setOpen(true)
    setSelectedPlan(plan)
  }

  const toggleModal = () => {
    setOpen(!isOpen)
  }
  return (
    <PaymentsProvider isLoggedIn={isLoggedIn}>
      <div className="payments-page">
        <PaymentOptions
          selectedPlan={selectedPlan}
          isOpen={isOpen}
          onClick={toggleModal}
        />
        <section
          className={`pricing ${showChristmasDeals ? 'christmas' : ''} py-4`}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    {showChristmasDeals && (
                      <div className="extra-days">+7 days Free</div>
                    )}
                    <h5 className="card-title text-muted text-uppercase text-center">
                      1 Month
                    </h5>
                    <h6 className="card-price text-center pricing-amount">
                      $7
                    </h6>
                    <hr />
                    <PremiumFeatures plan="1 Month" />
                    <div className="text-center d-flex justify-content-around flex-column align-items-center">
                      <LemonSqueezy
                        user={user}
                        isLoggedIn={isLoggedIn}
                        plan={1}
                      />
                      <CryptoPayment isLoggedIn={isLoggedIn} plan={1} />
                      <UpgradeButton
                        isLoggedIn={isLoggedIn}
                        plan={1}
                        handleClick={handleClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card mb-5 mb-lg-0">
                  <div className="card-body">
                    {showChristmasDeals && (
                      <div className="extra-days">+15 days Free</div>
                    )}
                    <h5 className="card-title text-muted text-uppercase text-center">
                      2 Months
                    </h5>
                    <h6 className="card-price text-center pricing-amount">
                      $13
                    </h6>
                    <hr />
                    <PremiumFeatures plan="2 Months" />
                    <div className="text-center d-flex justify-content-around flex-column align-items-center">
                      <LemonSqueezy
                        user={user}
                        isLoggedIn={isLoggedIn}
                        plan={2}
                      />
                      <CryptoPayment isLoggedIn={isLoggedIn} plan={2} />
                      <UpgradeButton
                        isLoggedIn={isLoggedIn}
                        plan={2}
                        handleClick={handleClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    {showChristmasDeals && (
                      <div className="extra-days">+30 days Free</div>
                    )}
                    <h5 className="card-title text-muted text-uppercase text-center">
                      3 Months
                    </h5>
                    <h6 className="card-price text-center pricing-amount">
                      $19
                    </h6>
                    <hr />
                    <PremiumFeatures plan="3 Months" />
                    <div className="text-center d-flex justify-content-around flex-column align-items-center">
                      <LemonSqueezy
                        user={user}
                        isLoggedIn={isLoggedIn}
                        plan={3}
                      />
                      <CryptoPayment isLoggedIn={isLoggedIn} plan={3} />
                      <UpgradeButton
                        isLoggedIn={isLoggedIn}
                        plan={3}
                        handleClick={handleClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          <Voucher isLoggedIn={isLoggedIn} />
        </div>
        <div className="container py-2">
          <h6 className="text-danger">Note :</h6>
          <ul>
            <li className="text-dark">
              Please check host status page before making any payment.
            </li>
            <li className="text-dark">
              Check daily size limits and Bandwidth usage limits.
            </li>
            <li className="text-dark">
              Filesize and Bandwidth limits can alter anytime without any
              notice. (increase or decrease)
            </li>
            <li className="text-dark">
              Once premium key is applied successfully then it can't be revoked.
              Also no refunds are possible.
            </li>
          </ul>
        </div>
      </div>
    </PaymentsProvider>
  )
}

export default Payments
