const PREMIUM_PLANS = {
  inr: {
    1: 'Rs.400',
    2: 'Rs.750',
    3: 'Rs.1200',
  },
  usd: {
    1: '$7',
    2: '$13',
    3: '$19',
  },
}

const getPayableAmount = (gateway, plan) => {
  if (gateway && gateway !== 'paytm') {
    return PREMIUM_PLANS.usd[plan || 1]
  }
  if (gateway) {
    return PREMIUM_PLANS.inr[plan || 1]
  }
  return null
}

export default getPayableAmount
