import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from 'gatsby'

import { Button, Alert, Spinner } from 'reactstrap'

import useForm from '../../hooks/useForm'

import { LOGIN, PREMIUM } from '../../data/constants'
import { PREMIUM_KEY } from '../../data/apis'

import InputBox from '../Form/InputBox'
import InvisibleRepatcha from '../InvisibleRepatcha'
import { premiumKeyValidations } from '../../validations'
import { postData, resetState } from '../../reducers/app'
import { loadUserInfo } from '../../reducers/user'

import styles from './index.module.scss'
import { UserContext } from '../UserContext'

const handleRedirect = () => navigate(`${LOGIN}/?redirect=${PREMIUM}`)

let premiumFormKey

const init = {
  premiumKey: '',
  captcha: true,
}

const PremiumKey = () => {
  const { values, errors, resetForm, handleSubmit, handleOnChange } = useForm(
    init,
    premiumKeyValidations,
    val => {
      premiumFormKey = val
      return premiumFormKey
    }
  )
  const { isLoggedIn } = useContext(UserContext)
  const {
    premiumkey: { loading, loaded, error, data },
  } = useSelector(state => state.app)
  const dispatch = useDispatch()

  const clearResponse = () => {
    dispatch(resetState(PREMIUM_KEY.key))
    resetForm()
  }

  const submitForm = token => {
    dispatch(
      postData(
        PREMIUM_KEY,
        {
          key: premiumFormKey.premiumKey,
          token,
        },
        () => dispatch(loadUserInfo())
      )
    ).finally(() => resetForm())
    const { grecaptcha } = window
    grecaptcha.reset()
  }

  return (
    <section className="premium-key">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 mb-3">
            <div className="card">
              <div className="card-body">
                <h5 className="mt-0">
                  <i className="far fa-hand-point-right pr-1" />
                  Our Reseller
                </h5>
                <p className="">Buy Premium key from our reseller below</p>
                <div>
                  <ul>
                    <li>
                      <a
                        className="font-weight-bold text-danger"
                        href="https://filesharingshop.com/cboxera-premium.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FileSharingShop
                      </a>
                    </li>
                    {/* <li>
                      <a
                        className="font-weight-bold text-danger"
                        href="https://premium.us/cboxera-paypal-premium.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Premium.us
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="card">
              <div className="card-body">
                <h5 className="text-center mt-0">
                  <li className="fas fa-key pr-2" />
                  Have a Premium Key?
                </h5>
                <div className="text-center">
                  {isLoggedIn ? (
                    <p className="text-dark">
                      Apply your Premium Key to Upgrade your account!
                    </p>
                  ) : (
                    <>
                      <p className="text-secondary font-weight-bold">
                        Please login first to enter your Premium key.
                      </p>
                      <br />
                      <Button
                        className="btn btn-danger"
                        onClick={handleRedirect}
                      >
                        Login
                      </Button>
                    </>
                  )}
                </div>
                {isLoggedIn && (
                  <form onSubmit={handleSubmit}>
                    <div className="d-flex flex-row justify-content-center">
                      <div className={`col-9 pr-0 ${styles.fixedWidth}`}>
                        <InputBox
                          name="premiumKey"
                          type="text"
                          placeholder="Enter Premium key"
                          value={values.premiumKey}
                          onChangeInput={handleOnChange}
                          error={errors.premiumKey}
                        />
                      </div>
                      <InvisibleRepatcha onResolved={submitForm} />
                      <div className="col-3 pl-0 pr-0">
                        <Button size="md" color="primary" disabled={loading}>
                          {loading ? (
                            <Spinner size="sm" color="light" />
                          ) : (
                            'Apply'
                          )}
                        </Button>
                      </div>
                    </div>
                  </form>
                )}
                <div className="container text-center">
                  {loaded && !loading && (
                    <Alert
                      color={`${error ? 'danger' : 'success'}`}
                      isOpen={loaded}
                      toggle={clearResponse}
                    >
                      <span>{error || (data && data.msg)}</span>
                      {data && data.days && (
                        <div className="text-center font-weight-bold">
                          Premium Left: {data.days} Days
                        </div>
                      )}
                    </Alert>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PremiumKey
