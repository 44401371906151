import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { getInvoice } from '../../reducers/invoice'

const CryptoPayment = ({ isLoggedIn, plan }) => {
  const [selectedPlan, setSelectedPlan] = useState(null)
  const { loading, data, isRedirecting } = useSelector(state => state.invoice)

  const dispatch = useDispatch()

  const handlePay = cplan => {
    setSelectedPlan(cplan)
    dispatch(
      getInvoice({
        gateway: 'crypto',
        plan,
      })
    )
  }

  useEffect(() => {
    if (data && data.payurl) {
      window.location.href = data.payurl
    }
  }, [data])

  useEffect(() => {
    return () => {
      setSelectedPlan(null)
    }
  }, [])

  return (
    <div className="my-1">
      {isLoggedIn ? (
        <button
          type="button"
          className="btn btn-danger"
          disabled={loading || isRedirecting}
          onClick={() => handlePay(plan)}
        >
          {(loading || isRedirecting) && selectedPlan === plan ? (
            <i className="fas fa-spinner fa-spin mr-2" />
          ) : (
            <i className="fab fa-bitcoin mr-2" />
          )}
          Pay with Crypto
        </button>
      ) : (
        <></>
      )}
    </div>
  )
}

CryptoPayment.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  // user: PropTypes.object.isRequired,
  // handleClick: PropTypes.func.isRequired,
  plan: PropTypes.number.isRequired,
}

export default CryptoPayment
