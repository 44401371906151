import React from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { Button } from 'reactstrap'

import { LOGIN, PREMIUM } from '../../data/constants'

const handleRedirect = () => navigate(`${LOGIN}/?redirect=${PREMIUM}`)

const UpgradeButton = ({ isLoggedIn, handleClick, plan }) => {
  return (
    <div className="my-1">
      {isLoggedIn ? (
        <Button className="btn btn-danger" onClick={handleClick(plan)}>
          <i className="fas fa-store mr-2"></i>
          Reseller
        </Button>
      ) : (
        <Button className="btn btn-danger" onClick={handleRedirect}>
          Login & Upgrade
        </Button>
      )}
    </div>
  )
}

UpgradeButton.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  plan: PropTypes.number.isRequired,
}

export default UpgradeButton
