import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const PaymentForm = ({
  data: { pay_url: payURL, form_data: formData },
  error,
}) => {
  const paymentFormRef = useRef(null)

  useEffect(() => {
    if (payURL && formData) {
      paymentFormRef.current.submit()
    }
  }, [formData])

  if (payURL && formData && !error) {
    const formFields = Object.entries(formData)
    return (
      <form
        ref={paymentFormRef}
        style={{ display: 'none' }}
        method="POST"
        action={payURL}
        encType="application/x-www-form-urlencoded"
      >
        {formFields.map(field => (
          <input
            key={field[0]}
            type="hidden"
            name={field[0]}
            value={field[1]}
          />
        ))}
      </form>
    )
  }
  return null
}

PaymentForm.propTypes = {
  data: PropTypes.object,
  error: PropTypes.string,
}

PaymentForm.defaultProps = {
  data: null,
  error: '',
}

export default PaymentForm
