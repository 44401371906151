import React from 'react'
import Adsense from '../components/Adsense'
import Payments from '../components/Payments'
import SEO from '../components/SEO'

const PremiumPlans = () => {
  return (
    <>
      <SEO page="premium" />
      <Payments />
      <Adsense slot="6222161092" format="responsive" />
    </>
  )
}

export default PremiumPlans
