import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Fade,
} from 'reactstrap'
import { useEffect } from 'react'
import PaymentMethods from './PaymentMethods'
import PaymentForm from './PaymentForm'
import { getInvoice } from '../../reducers/invoice'
import getPayableAmount from '../../data/vip-services'
// import StripeCheckout from './StripeCheckout'

const PaymentOptions = ({ selectedPlan, isOpen, onClick }) => {
  const [gateway, setGateway] = useState('')
  const { loading, loaded, data, error, isRedirecting } = useSelector(
    state => state.invoice
  )

  const onChange = e => {
    const {
      target: { value },
    } = e
    setGateway(value)
  }

  useEffect(() => {
    if (data) {
      if (gateway !== 'paytm' && data.payurl) {
        window.location.href = data.payurl
      }
    }
  }, [data])

  const dispatch = useDispatch()

  const handlePay = e => {
    e.preventDefault()
    dispatch(
      getInvoice({
        gateway,
        plan: selectedPlan,
      })
    )
  }

  const price = getPayableAmount(gateway, selectedPlan)
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={onClick} className="payment-options">
        Payment options - {selectedPlan} month{selectedPlan === 1 ? '' : 's'}{' '}
        Premium
      </ModalHeader>
      <ModalBody>
        <PaymentMethods onChange={onChange} gateway={gateway} />
      </ModalBody>
      <ModalFooter
        className={`modal-footer-padding ${
          !(isRedirecting || loading) ? 'payment-modal-footer' : ''
        }`}
      >
        {/* eslint-disable */}
        <Fragment>
          {!error && !loading && !isRedirecting && (
            <Fade
              in={!!price}
              tag="div"
              className="font-weight-bold text-danger"
            >
              Total: {price}{' '}
              {/^(fss-|pmu-)/.test(gateway) ? (
                <small>(excluding reseller charges)</small>
              ) : (
                <small>(inc. all taxes) </small>
              )}
            </Fade>
          )}
          {loaded && !loading && error && (
            <div className="font-weight-bold text-danger">Oops! {error}</div>
          )}
        </Fragment>
        <Button
          size="sm"
          color="success"
          onClick={handlePay}
          disabled={!gateway || loading || isRedirecting}
        >
          {loading || isRedirecting ? 'Please wait....' : 'Pay Now'}
        </Button>{' '}
      </ModalFooter>
      {gateway === 'paytm' && data && isRedirecting && (
        <PaymentForm data={data} error={error} />
      )}
      {/* {gateway === 'stripe' && !error && <StripeCheckout data={data} />} */}
      {/* eslint-enable */}
    </Modal>
  )
}

PaymentOptions.defaultProps = {
  isOpen: false,
  selectedPlan: 1,
}

PaymentOptions.propTypes = {
  isOpen: PropTypes.bool,
  selectedPlan: PropTypes.number,
  onClick: PropTypes.func.isRequired,
}

export default PaymentOptions
