import React, { createContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { PAYMENT_OPTIONS } from '../../data/apis'
import { getData } from '../../reducers/app'

export const PaymentsContext = createContext()

export const PaymentsProvider = ({ children, isLoggedIn }) => {
  const dispatch = useDispatch()
  const { paymentoptions } = useSelector(state => state.app)
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getData(PAYMENT_OPTIONS))
    }
  }, [isLoggedIn])
  return (
    <PaymentsContext.Provider value={paymentoptions}>
      {children}
    </PaymentsContext.Provider>
  )
}

PaymentsProvider.propTypes = {
  children: PropTypes.any.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
}
